import "@clout-team/web-components/build/index.css";
import "./App.scss";

import {DialogAssembly, WebHeader} from "@clout-team/web-components";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import { initSOCKET } from "./api/socket";
import { useInit } from "./hooks/useInit";
import { setCurrentUser } from "./redux/actions/currentAction";
import dayjs from "dayjs";

import {getUrlParamByKey, isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import {initIPC} from "./redux/localDB";
import MainPage from "./pages/MainPage/MainPage";
import NewWindowContact from "./pages/NewWindowContact/NewWindowContact";
import { Chat, MiniChat } from "./interfaces/chats";
import { useAppContext } from "./hooks/useAppContext";
import { getChatListAPI, shareContact } from "./api/chat";
import Header from "./components/Header/Header";
import { Profile } from "./interfaces/profile";

localStorage.setItem(
  "releaseDate",
  process.env.REACT_APP_RELEASE_DATE
    ? dayjs(process.env.REACT_APP_RELEASE_DATE).format("DD.MM.YYYY") + ""
    : "null"
);

const App = () => {
  const { L } = useAppContext();

  const dispatch = useDispatch();
  useInit();
  const currentUser = useSelector((store: any) => store.currentUser);
  const [chats, setChats] = useState<MiniChat[]>([]);
  const [share, setShare] = useState<any>(null);
  useEffect(() => {
    (async () => {
      const socket = initSOCKET();
      socket?.open();
      if (isElectron()) initIPC();

      dispatch(setCurrentUser());
      return () => socket?.disconnect();
    })();
  }, [dispatch]);
  useEffect(() => {
    document.title = `${L("contacts.title.contacts")} | Lenza`;
  }, [L]);
  useEffect(() => {
    const listener = async (ev: any) => {
      const user = ev.detail as Profile;
      if (!chats?.length) {
        getChatListAPI().then((res) => {
          setChats(res.item);
        });
      }
      setShare(user);
    };
    document.addEventListener("share-contact", listener, { passive: true });
    return () => {
      document.removeEventListener("share-contact", listener);
    };
  }, []);
  const isShowContact = getUrlParamByKey("showContact");
  return (
    <>
      <Header />
      {chats.length > 0 && share && currentUser && (
        <DialogAssembly.ChatSelectModal
          user={currentUser}
          sendButtonText={L("button.share")}
          title={(count) => {
            return L("chat.label.share_contact_to", {
              variables: { __number__: "" },
            });
          }}
          onSubmit={(checked, docs, contact) => {
            Promise.all(
              checked.map((chat) => {
                return shareContact(chat?.id, share?.hash);
              })
            ).then(() => {
              try {
                if (checked.length === 1) {
                  // window.ipcRenderer.send('request-open-chat-or-message', (checked[0]?.id), undefined);
                }
              } catch (e) {
                console.log(e);
              }
            });
          }}
          contact={share}
          chats={chats}
          onClose={() => {
            setShare(null);
          }}
        />
      )}
      {isShowContact ? <NewWindowContact /> : <MainPage />}
    </>
  );
};

export default App;
