import {objectToSmallCase} from "../../utils/functions";
import * as TYPES from "../actionTypes/actionTypes";
import {store} from "../store";

export const initProfile = () => {
  // @ts-ignore
  window.ipcRenderer.send('clout-event-profile', {type: 'profile:get'});
  // @ts-ignore
  window.ipcRenderer.on('clout-event-profile', (e: any, data: any) => {
    if (data.type !== 'profile:set') return;

    const profile = data.profile;
    store.dispatch({
      type: TYPES.SET_CURRENT_USER,
      payload: objectToSmallCase(profile)
    });
    console.groupCollapsed("Contacts - инициализация профиля")
    console.log(profile)
    console.groupEnd()
  })
}

export const initContacts = () => {
  const data = localStorage.getItem('users');
  if (data) {
    const contacts = Object.values(JSON.parse(data)).filter((u: any) => u.is_friend === 1 || u.is_you === 1 || u.is_company === 1 || u.is_system === 1)
    console.log(contacts)
    let friends: any[] = [];
    let teammates: any[] = [];
    let system: any[] = [];

    (contacts ?? []).forEach((contact: any) => {
      const _contact = objectToSmallCase(contact)
      if (_contact.is_friend === 1)
        friends.push({
          ..._contact,
          group: _contact.name.substring(0, 1)
        })
      if (_contact.is_system === 1)
        system.push(_contact)
      if ((_contact.is_company === 1 && _contact.status !== 'DEACTIVATED'))
        teammates.push(_contact)
    })
    console.groupCollapsed("Contacts - инициализация контактов")
    console.log("Contacts: system:", system)
    console.log("Contacts: friends:", friends)
    console.log("Contacts: teammates:", friends)
    console.groupEnd()
    store.dispatch({
      type: TYPES.SET_MY_CONTACTS_LIST,
      payload: {
        myCompany: [],
        recent: [],
        system: system,
        accepted: friends,
        teammates: teammates
      },
    });

    // TODO потом удалить
    // setTimeout(() => {
    //   localStorage.removeItem('users');
    // }, 1000)
  }
}

export const initIPC = () => {
  // @ts-ignore
  window.ipcRenderer.on('clout-event-contacts', (e: any, data: any) => {

    const createUserData = (user: any) => {
      return {
        ...user,
        avatar: user.avatar,
        domain: user.domain,
        hash: user.hash,
        name: user.name,
        surname: user.surname,
        group: user.name.split('')[0],
        login: user.login,
        is_friend: user.isFriend,
        is_company: user.isCompany,
        subject_id: user.subjectId,
      }
    }

    if (data.type === 'contacts:update-profile-app') {
      const profile = data.profile;

      const newUserData = createUserData(profile);
      console.log(newUserData)
      store.dispatch({
        type: TYPES.UPDATE_MY_CONTACTS_LIST,
        payload: {
          hash: profile.hash,
          contact: newUserData
        },
      })
      console.groupCollapsed("Contacts: Обновление контактов")
      console.log(newUserData)
      console.groupEnd()
    } else if (data.type === 'contacts:add-profile-app') {
      const profile = data.profile;

      const newUserData = createUserData(profile);
      store.dispatch({
        type: TYPES.ADD_MY_CONTACTS_LIST2,
        payload: {
          hash: profile.hash,
          contact: newUserData
        },
      })

      console.groupCollapsed("Contacts: Добавление контакта")
      console.log(newUserData)
      console.groupEnd()
    } else if (data.type === 'contacts:deactivate-app') {
      store.dispatch({
        type: TYPES.DELETE_COMPANY_CONTACT,
        payload: {
          hash: data.hash
        }
      })

      console.groupCollapsed("Contacts: Деактивация контакта")
      console.log(data.hash)
      console.groupEnd()
    }
  })
}
