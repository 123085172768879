import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setCurrentModal,
  setFoundContact,
} from "../../redux/actions/currentAction";
import { RootState } from "../../redux/store";
import { calcLastSeenAgoTime } from "../../utils/functions";
import ProfileCard from "../ProfileCard/ProfileCard";
import { useAppContext } from "../../hooks/useAppContext";

interface FoundProfileProps {}

const FoundProfile = (props: FoundProfileProps) => {
  const dispatch = useDispatch();
  const {L} = useAppContext();
  const foundContact = useSelector(
    (state: RootState) => state.foundContact.contact
  );
  const {
    hash,
    name,
    avatar,
    surname,
    lastEnter,
    login,
    phone,
    status,
    subject_id,
    domain
  } = foundContact;

  const handleCloseButton = () => {
    dispatch(setCurrentModal(null));
    dispatch(setFoundContact({ contact: {}, searchPhrase: "" }));
  };
  const { number, timeFrame } = calcLastSeenAgoTime(lastEnter);
  const lastSeenAgo = `${number} ${timeFrame} ${L("contacts.info.last_seen_ago")}`;

  return (
    <ProfileCard
      contactId={hash}
      currentContact={foundContact}
      isBackButton={true}
      backButtonClickFunc={() => {}}
      isModal={true}
      canBeClosed={true}
      onCloseClickFunc={handleCloseButton}
      profileTitle={`${name} ${surname}`}
      lastSeenAgo={lastSeenAgo}
      profileCloutId={`${login}@${domain}`}
      domain={domain}
      profilePhoneNumber={`+${phone}`}
      profileStatus={status}
      profileAvatar={avatar}
      idOfChatWithContact={subject_id}
    />
  );
};

export default FoundProfile;
