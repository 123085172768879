export const AUTH_TOKEN = "4e08d1d225a71441156a198302bb9c74f0d5ad0a5a2a6a5dbf";

export const contextChildrenForWelcome = [
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f26",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f25",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f24",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f23",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f22",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f21",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f20",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f29",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f27",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f28",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
  {
    hash: "0737e3d1-dcc1-402b-a5c8-484e79881f19",
    name: "Test 2",
    surname: "User",
    patronymic: "Patronymic",
    fullName: "Test2 User Name",
    phone: "7715167817",
    email: "email2@ya.ru",
    login: "test2_login@clout",
    lastEnter: "2021-09-30 13:10:24",
    status: "ACCEPTED",
    avatar: "",
    group: "S",
  },
];
