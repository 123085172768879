import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Contact } from "../../interfaces/contact";
import { setCurrentContact } from "../../redux/actions/currentAction";
import { RootState } from "../../redux/store";
import ContactListItem from "./ContactListItem";

interface ContactListItemContainerProps {
  contact: Contact;
  iconRightForIncomingRequests?: any;
  iconRightForOutgoingRequests?: any;
  isCompanyList?: boolean
}

const ContactListItemContainer: FC<ContactListItemContainerProps> = ({
  contact,
  iconRightForIncomingRequests,
  iconRightForOutgoingRequests,
  isCompanyList
}) => {
  const userChosen = useSelector((state: RootState) => state.currentContact);
  const myself = useSelector((state: RootState) => state.currentUser);
  const dispatch = useDispatch();

  const handleClickOnContact = useCallback(
    (contact: Contact) => {
      dispatch(setCurrentContact(contact.hash));
    },
    [dispatch]
  );

  return (
    <ContactListItem
      key={contact?.hash}
      contact={contact}
      myself={myself}
      isCompanyList={isCompanyList}
      isContactActive={(userChosen) === (contact?.hash)}
      handleClickOnContact={handleClickOnContact}
      iconRightForIncomingRequests={iconRightForIncomingRequests}
      iconRightForOutgoingRequests={iconRightForOutgoingRequests}
    />
  );
};

export default ContactListItemContainer;
