import "./ContactList.scss";

import IconAdd from "@clout-team/icons/react/Add";
import { Header, IconButton, Text } from "@clout-team/web-components";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setCurrentModal, setMyContactsList } from "../../redux/actions/currentAction";
import ContactConsoleContainer from "../ContactConsole/ContactConsoleContainer";
import { useAppContext } from "../../hooks/useAppContext";

interface ContactListProps {
}

const ContactList = (props: ContactListProps) => {
  const dispatch = useDispatch();
  const { L } = useAppContext();

  useEffect(() => {
    dispatch(setMyContactsList());
  }, [dispatch]);

  return (
    <div className="contacts-list-div">
      <Header
        size="lg"
        left={L("contacts.title.contacts")}
        /*right={
          <IconButton
            icon={<IconAdd/>}
            onClick={() => dispatch(setCurrentModal("add_new_contact"))}
          />
        }*/
      />
      <ContactConsoleContainer/>
    </div>
  );
};

export default ContactList;
