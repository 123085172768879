import * as TYPES from "../actionTypes/actionTypes";

function activeChatReducer(state: any = [], action: any) {
  switch (action.type) {
    case TYPES.SET_ACTIVE_CHAT:
      return action.payload;
    default:
      return state;
  }
}

export default activeChatReducer;
