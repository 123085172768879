import { CHAT_URL, CREATE_CHAT, SHARE_CONTACT } from "../../utils/urls";
import { API } from "../makeRequests";
import {API as APIComponent} from "@clout-team/web-components"
export const createDialogWithGoToChat = async (
  userId: string
): Promise<any> => {
  const uri = `${CHAT_URL}${userId}`;
  const response = await API.post(uri);
  if (!response.ok) throw new Error("Not found");
  return await response.json();
};

export const shareContact = async (
  subject_id: number,
  hash: string
): Promise<any> => {
  // const url = `${SHARE_CONTACT}`.replace("{hash}", String(hash));

  // const response = await API.post(`${url}?subject_id=${subject_id}`);
  // if (!response.ok) throw new Error("Not found");
  return new APIComponent().user().share({
    hash: hash,
    subject_id: subject_id
  })
};
export const createChat = async (hash: string): Promise<any> => {
  const response = await API.post(`${CREATE_CHAT}?id=${hash}`);
  if (!response.ok) throw new Error("Not found");
  return await response.json();
};

export const getChatListAPI = () => {
  const req = new APIComponent().chat().list({limit: 100, mini: true, is_archive: false});
  return req
}