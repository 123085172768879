import React, { useEffect } from "react";
import {ChatInfo, ContactsController as CC} from "@clout-team/web-components";

import * as TYPES from "../../redux/actionTypes/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../redux/store";
import { objectToSmallCase } from "../../utils/functions";
import './ProfileCard.scss'
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import { createChat, getChatListAPI } from "../../api/chat";
import {Profile} from '../../interfaces/profile'
interface ProfileCardProps {
  contactId: string;
  isBackButton?: boolean;
  backButtonClickFunc?: () => void;
  isModal?: boolean;
  canBeClosed?: boolean;
  onCloseClickFunc?: () => void;
  profileTitle: string;
  profileAvatar: string | undefined;
  lastSeenAgo: string;
  profileCloutId: string;
  profilePhoneNumber: string;
  profileStatus: string | null;
  idOfChatWithContact: string;
  domain: string;
  currentContact: any
}
const {Contacts} = CC;

const ProfileCard = (props: ProfileCardProps) => {
  const {
    currentContact,
    onCloseClickFunc,
  } = props;

  const me = Contacts.me()

  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.currentUser);

  return (
    <ChatInfo
      onClose={onCloseClickFunc ?? (() => {})}
      contactViewMode
      chatInfoActions={{
        openChat: () => {

          const currentURL = window.location.href
          let preLink = "dev-"
          let type = ""

          if (currentURL.includes("dev")) {
            preLink = "dev-"
          } else if (currentURL.includes("stable")) {
            preLink = "stable-"
          }
          if (currentContact.domain === "bot") {
            type = "bot"
          } else type = "direct"

          if(currentContact?.subject_hash){
            if(!isElectron()) {
              const link = `https://${preLink}chat.lenzaos.com/${currentContact.subject_hash}/${type}/${me.domain}`
              window.open(link)
            } else {
              window.ipcRenderer.send('request-open-chat-or-message', currentContact?.subject_hash, undefined)
            }
          } else {
            createChat(currentContact?.id).then((res) => {
              if(!isElectron()) {
                dispatch({
                  type: TYPES.UPDATE_USER,
                  payload: {
                    ...currentContact,
                    subject_id: res.id
                  }
                })
                const link = `https://${preLink}chat.lenzaos.com/${res.response.id}/${type}/${me.domain}`
                window.open(link)
              } else {
                window.ipcRenderer.send('request-open-chat-or-message', res.id, undefined)
              }
            }) 
          }
        },
        onCall: () => {
          if(isElectron()){
            window.ipcRenderer.send("electron-event", {
              type: "request-initial-call",
              subjectId: currentContact?.subject_id,
            });
          } else {
            const tmpId = +new Date()
            if (window?.callToChat) {
              window?.callToChat(currentContact.subject_id, tmpId)
            }
            // initSOCKET()?.emit("meeting", {
            //   event: "create",
            //   subject_id: currentContact?.subject_id,
            //   tmp_key: ""
            // });
          }
        },
        onShareContact: (hash: any) => {},
        setUser: (user: any) => {
          store.dispatch({
            type: TYPES.SET_CURRENT_USER,
            payload: user
          })
          store.dispatch({
            type: TYPES.UPDATE_MY_CONTACTS_LIST,
            payload: {
              hash: user.hash,
              contact: user
            },
          })
        },
        onAddContact: (hash: number) => {
          const updateContact = (chatId?: any) => {
            store.dispatch({
              type: TYPES.ADD_MY_CONTACTS_LIST2,
              payload: {
                hash: hash,
                contact: {
                  ...currentContact,
                  is_friend: true,
                  isFriend: 1,
                  subject_id: currentContact?.subject_id ?? chatId
                }
              },
            })
          }
          createChat(currentContact?.hash).then((res) => {
            updateContact(res?.response?.id)
          })
          
        },
        
      }}
      hideMoreBtn
      user={objectToSmallCase(currentUser)}
      contact={objectToSmallCase(currentContact)}
      useContactsControllers={false}
      hideShareBtn={["DEACTIVATED", "DELETED"].includes(currentContact?.status)}
      myProfile={(currentContact?.hash) === (currentUser?.hash)}
      dialogProps={{
        isModal: false,
        trafficLight: {
          visibly: false,
          hasOpenNewWindow: false,
          hasSubtract: false,
          hasMaximize: false,
          hasDismiss: false,
          hasDivider: false,
          onDismiss: () => onCloseClickFunc ?? (() => {}),
          openInNewWindow: () => {
            const item = localStorage.getItem('electronSettings');
            const electronSettings = item && JSON.parse(item);
            if (isElectron() && parseInt(electronSettings?.isOpenInWindow) === 1) {
              window.ipcRenderer?.send('show-clout-url', {
                url: `${document.location.origin + document.location.pathname}?showContact=true&hash=${currentContact?.hash}&app_electron=1`,
                type: 'profile'
              });
            }
          }
        }
      }}
    />
  )
};

export default ProfileCard;
