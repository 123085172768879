import { store } from "../../../redux/store";
import * as TYPES from "../../../redux/actionTypes/actionTypes";
import { isElectron } from "@clout-team/helpers/dist/utils/web-utils";

export const listenerUser: Record<string, (data: any) => void> = {
    // 'setFriends': (data: any) => {
    //     const newUserData = {
    //         avatar: data.avatar,
    //         domain: data.domain,
    //         id: parseInt(data.id),
    //         name: data.name,
    //         surname: data.surname,
    //         login: data.login,
    //         is_friend: data.is_friend,
    //         is_company: data.company_id === store.getState().currentUser.domain === data.domain,
    //         subject_id: data.subject_id,
    //     }

  //     store.dispatch({
  //         type: TYPES.ADD_MY_CONTACTS_LIST,
  //         payload: {
  //             hash: data.hash,
  //             contact: newUserData
  //         },
  //     })
  // },
  "user.add": (data: any) => {
    store.dispatch({
      type: TYPES.ADD_USER,
      payload: {
        contact: data?.user,
      },
    })
      store.dispatch({
        type: TYPES.ADD_MY_CONTACTS_LIST2,
        payload: {
          contact: data.user,
          hash: data.user_hash,
        },
      });
  },
  "user.add-you": (data: any) => {
    store.dispatch({
      type: TYPES.USER_ADD_YOU,
      payload: {
        contact: data,
      },
    });
  },
  "user.del": (data: any) => {
    store.dispatch({
      type: TYPES.DELETED_CONTACT,
      payload: {
        hash: data.hash,
      },
    });

    /*// @ts-ignore
        if (isElectron()) window.ipcRenderer.send("deleted-contact", {data})*/
  },
  "user-offline": () => {},
  "user-online": () => {},
};
