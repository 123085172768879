import { io } from "socket.io-client";
import { store } from "../../redux/store";
import { getContactCompany } from "../biz";

import {SOCKET_URL, MEETING_URL} from "../../utils/urls";
import {getCookie} from "@clout-team/helpers";
import {listenerBiz} from "./biz";
import {listenerProfile} from "./profile";
import {listenerUser} from "./user";
import {AUTH_TOKEN} from "../../utils/mock";
import * as TYPES from "../../redux/actionTypes/actionTypes"
import {getLastOnline} from "../user";
import {Contact} from "../../interfaces/contact";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";

export const initSOCKET = () => {

  // if( isElectron() )
  //   return;

  try {
    const socket = io(SOCKET_URL, {
      query: {
        token: getCookie("token")
          ? isElectron()
            ? localStorage.getItem("token")
            : getCookie("token")
          : AUTH_TOKEN
      },
      autoConnect: true,
      reconnection: true,
      path: "/socket_1/",
      transports: ["websocket"],
    });

    socket.on("auth", async (data: any) => {

      if (data.event === "companyUpdate") {
        const { response } = await getContactCompany(data.data.hash);
        const teammates = store.getState().myContactsList.teammates;

        const newTeammates = await Promise.all(
          response.users.map(async (item: Contact) => {
            if (
              !!teammates.find(
                (teammate: Contact) =>
                  parseInt(teammate.hash) === parseInt(item.hash)
              )
            ) {
              return {
                ...item,
                ...teammates.find(
                  (teammate: Contact) => teammate.hash === item.hash
                ),
              };
            }
            return {
              ...item,
              lastEnter: await getLastOnline(item.hash),
              status: "TEAMMATE",
              group: item.name.charAt(0),
            };
          })
        );
        store.dispatch({
          type: TYPES.GET_TEAMMATES,
          payload: {
            users: newTeammates,
          },
        });
      }

      if (data === true) {
      } else {
        socket.disconnect();
      }
    });
    socket.on("profile", (e: any) => {
      const data = e.data;

      if (listenerProfile && listenerProfile[e.event]) {
        listenerProfile[e.event](data)
      }
    });
    socket.on("biz", (e: any) => {
      const data = e.data;

      if (listenerBiz && listenerBiz[e.event]) {
        listenerBiz[e.event](data)
      }
    });
    socket.on("user", (e: {
      event: string,
      data: any
    }) => {
      const data = e.data;

      if (listenerUser && listenerUser[e.event]) {
        listenerUser[e.event](data)
      }
    });
    socket.on("meeting", (e: {
      event: string,
      data: any
    }) => {
      const data = e.data;

      if(e.event === "room_created" && window.callsTmpKey && window.callsTmpKey === data.tmp_key) {
        window.open(`${MEETING_URL}?hash=${data.link}`, "lenzaosCall")
      }
    });
    socket.on("connect", () => {
    });
    socket.on("disconnect", () => {
    });

    if(!window.callToChat) window.callToChat = (subject_id, tmpId) => {
      window.callsTmpKey = String(tmpId);
      socket.emit('meeting', {
        event: "create",
        subject_id: subject_id,
        tmp_key: tmpId,
        version: '2'
      })
    }

    return socket;
  } catch (e) {
    console.log(e);
  }
};
