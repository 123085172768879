export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_CONTACT = "SET_CURRENT_CONTACT";
export const SET_FOUND_CONTACT = "SET_FOUND_CONTACT";
export const SET_MY_CONTACTS_LIST = "SET_MY_CONTACTS_LIST";
export const UPDATE_MY_CONTACTS_LIST = "UPDATE_MY_CONTACTS_LIST";
export const ADD_MY_CONTACTS_LIST2 = "ADD_MY_CONTACTS_LIST2";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_USER = "UPDATE_USER";
export const USER_ADD_YOU = "USER_ADD_YOU";
export const ADD_MY_CONTACTS_LIST = "ADD_MY_CONTACTS_LIST";
export const ADD_USER = "ADD_USER";
export const GET_TEAMMATES = "GET_TEAMMATES";
export const SET_CURRENT_CONTACTS_TAB = "SET_CURRENT_CONTACTS_TAB";
export const SET_CURRENT_MODAL = "SET_CURRENT_MODAL";
export const SET_NEW_TEAMMATES_LIST = "SET_NEW_TEAMMATES_LIST";
export const SET_LOCALIZATION = "SET_LOCALIZATION";
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";
export const BLOCKED_USER_COMPANY = "BLOCKED_USER_COMPANY";
export const DELETE_COMPANY_CONTACT = "DELETE_COMPANY_CONTACT";
export const DELETED_CONTACT = "DELETED_CONTACT";
