import React, {useState} from 'react';
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";
import {WebHeader} from "@clout-team/web-components";

const Header = () => {
    const [search, setSearch] = useState("")

    return (
        isElectron() ? <></> : <>
            <WebHeader
                search={search}
                onSearch={() => {}}
                changeSearch={val => setSearch(val)}
                module={"Contacts"}
            />
        </>
    );
};

export default Header;