import { User } from "../../interfaces/user";
import {
  ADD_FRIEND,
  GET_FRIENDS,
  GET_INCOMING_REQUESTS,
  GET_LAST_ONLINE,
  GET_OUTGOING_REQUESTS,
  GET_TEAMMATES
} from "../../utils/urls";
import { API } from "../makeRequests";

export const getFriends = async (): Promise<User | undefined> => {
  try {
      const uri = GET_FRIENDS;
      const response = await API.get(uri);
      const data = await response.json();
      return data;
  } catch (error) {
    console.log(error);
  }
};

export const getRequestsInbox = async (): Promise<User | undefined> => {
  try {
    const uri = GET_INCOMING_REQUESTS;
    const response = await API.get(uri);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getRequestsOutgoing = async (): Promise<User | undefined> => {
  try {
    const uri = GET_OUTGOING_REQUESTS;
    const response = await API.get(uri);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getLastOnline = async (userId: string) => new Promise((res) => {
  try {
    API.get(`${GET_LAST_ONLINE}${userId}`).then(js => js.json()).then(r => res(r.response));
  }
  catch (e) {
    console.log(e);
  }
});
export const addFriend = async (user_id: string, message: string) => new Promise((res) => {
  try {
    const _message = message ? `&message=${message}` : ""
    API.post(`${ADD_FRIEND}${user_id}${_message}`).then(js => js.json()).then(r => res(r.response));
  }
  catch (e) {
    console.log(e);
  }
});
