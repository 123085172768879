import "./AddNewContact.scss";

import Person from "@clout-team/icons/react/Person";
import { Button, Dialog, Input } from "@clout-team/web-components";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { searchContactByCloutId } from "../../api/contact";
import { setCurrentModal, setFoundContact } from "../../redux/actions/currentAction";
import { RootState } from "../../redux/store";
import { isValidCloutId, isValidPhone } from "../../utils/functions";
import { useAppContext } from "../../hooks/useAppContext";
import {getLastOnline} from "../../api/user";

interface AddNewContactProps {
}

const AddNewContact = (props: AddNewContactProps) => {
  const dispatch = useDispatch();
  const { L } = useAppContext();
  const [inputValue, setInputValue] = useState<string>(useSelector((state: RootState) => state.foundContact.searchPhrase));
  const [isDisabled, setIsDisabled] = useState<boolean>(!isValidCloutId(inputValue) && !isValidPhone(inputValue));
  const [isVisibleError, setIsVisibleError] = useState<boolean>(false);
  const [isSearchInProgress, setIsSearchInProgress] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isVisibleError) {
      setIsVisibleError(false);
    }
    setInputValue(event.target.value);
    setIsDisabled(!isValidCloutId(event.target.value) && !isValidPhone(event.target.value));
  };
  const handleSearchClick = async () => {
    setIsSearchInProgress(true);
    setIsVisibleError(false);
    let fetchedContacts = await searchContactByCloutId(
      inputValue
        .replaceAll("-", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("+", "")
    );
    if (fetchedContacts.length) {
      dispatch(
        setFoundContact({
          contact: {
            ...fetchedContacts[0],
            status: "ACCEPTED",
            lastEnter: await getLastOnline(fetchedContacts[0].hash)
          },
          searchPhrase: inputValue
            .replaceAll("-", "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("+", "")
        })
      );
      setIsSearchInProgress(false);
    } else {
      setIsSearchInProgress(false);
      setIsVisibleError(true);
    }
  };
  const handleDismissClick = () => {
    setInputValue("");
    setIsDisabled(true);
    setIsVisibleError(false);
    dispatch(setFoundContact({ contact: {}, searchPhrase: "" }));
  };

  const childrenForAddContactDialog = (
    <Input
      value={inputValue}
      placeholder={L("contacts.input.id_mobile")}
      iconLeft={<Person/>}
      description={L("contacts.description.enter_clout_id")}
      errorMessage={isVisibleError ? L("contacts.info.not_found") : ""}
      onChange={handleInputChange}
      onDismissClick={handleDismissClick}
    />
  );

  return (
    <Dialog
      closeOnClickOutside={false}
      dialogTitle={{ title: L("button.search") }}
      dialogButtons={{
        right: [
          <Button
            key="cancel_button"
            children={L("button.cancel")}
            inverted
            size="lg"
            onClick={() => {
              dispatch(setCurrentModal(null));
              dispatch(setFoundContact({ contact: {}, searchPhrase: "" }));
            }}
          />,
          <Button
            key="search_button"
            children={L("button.search")}
            size="lg"
            disabled={isDisabled}
            onClick={handleSearchClick}
            loading={isSearchInProgress}
          />
        ]
      }}
      onClose={() => {
        dispatch(setFoundContact({ contact: {}, searchPhrase: "" }));
        dispatch(setCurrentModal(null));
      }}
    >
      {childrenForAddContactDialog}
    </Dialog>
  );
};

export default AddNewContact;
