import {FC} from "react";
import {Dialog, ListItem} from "@clout-team/web-components";

interface IVersionDialog{

}

export const VersionDialog: FC<IVersionDialog> = (props: IVersionDialog) => {
    return (
        <Dialog
            isModal={true}
            onClose={() => {
                localStorage.removeItem("show_version");
                window.dispatchEvent(new Event("storage"));
            }}
            dialogTitle={{
                title: "VERSION",
                varOnlyTitle: false,
            }}>
            <>
                <ListItem title={`VERSION: ${localStorage.getItem("contactVersion")}`} size={"md"} />
                <ListItem title={`RELEASE DATE: ${localStorage.getItem("releaseDate")}`} size={"md"} />
            </>
        </Dialog>
    )
}