import { fetchClient } from "@clout-team/fetch-client";
import { Cookies } from "react-cookie";

import { AUTH_TOKEN } from "../utils/mock";
import { BASE_URL } from "../utils/urls";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";

const cookies = new Cookies();

export const API = fetchClient.init({
  logger: false,
  headers: {
    lang: "en",
  },
  handleResponseHeader: (headers: Headers) => {
    const authToken = headers.get("authorization");
    if (authToken) {
      cookies.set("authorization", authToken);
    }
  },
  handleRequestHeader: async (headers: Record<string, string>) => {
    // TODO: убрать захардкоженный токен, когда подключим авторизацию
    // const authToken = cookies.get("token");
    let authToken:string|null = null
    if (isElectron()) {
      authToken = localStorage.getItem("token")
    } else {
      authToken = cookies.get('token') ? cookies.get('token') : AUTH_TOKEN;
    }

    if (authToken) {
      return { ...headers, token: `${authToken}` };
    }
    return headers;
  },
  baseUrl: BASE_URL,
});

enum Method {
  GET = "get",
  PUT = "put",
  POST = "post",
  PATCH = "patch",
  DELETE = "delete",
}

export interface RequestConfigs {
  data?: any;
  method?: Method;
  headers?: Record<string, string>;
  excludeHeaders?: string[];
}

export interface FetchClientConfigs {
  baseUrl: string;
  fetchTimeout?: number;
  logger?: boolean;
  headers?: Record<string, string>;
  handleRequestHeader?: (
    headers: Record<string, string>,
    requestData?: any
  ) => Promise<Record<string, string>>;
  handleResponseHeader?: (headers: Headers, requestData?: any) => void;
}
