import {
  applyMiddleware,
  createStore
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import appReducer from "./reducers/index";

const initState = {
  currentUser: {},
  myContactsList: { isLoading: true },
  currentContact: null,
  newTeammatesList: [],
  foundContact: { contact: null, searchPhrase: "" },
  currentContactsTab: 0,
  currentModal: null,
  activeChat: "",
};

export const store = createStore(
  appReducer,
  initState,
  applyMiddleware(thunk)
);

export const SHARED_STORE_NAME = "contacts";

export type RootState = ReturnType<typeof store.getState>;
