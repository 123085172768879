import { Header, Text } from "@clout-team/web-components";
import _ from "lodash";
import React, { FC } from "react";

import { Contact } from "../../interfaces/contact";
import ContactListItemContainer from "../ContactListItem/ContactListItemContainer";

interface ContactListGroupProps {
  groupName: string;
  groupContacts: Contact[];
  isCompanyList?: boolean
}
const ContactListGroup: FC<ContactListGroupProps> = ({
  groupName,
  groupContacts,
  isCompanyList
}) => (
  <div>
    {groupName !== 'undefined' ? (
      <Header
        size="sm"
        left={<Text color="grey--80" fontSize="13-20" children={groupName} />}
      />
    ) : null}
    {groupContacts.map((groupContact: Contact, index) => (
      <ContactListItemContainer key={groupContact.hash + index} isCompanyList={isCompanyList} contact={groupContact} />
    ))}
  </div>
);

export default React.memo(ContactListGroup, _.isEqual);
