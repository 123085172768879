import "./ContactConsole.scss";

import { Dividers, Overflow, Tab } from "@clout-team/web-components";
import React, {useEffect, useMemo, useState, useCallback} from "react";
import { useDispatch } from "react-redux";

import { setCurrentContactsTab } from "../../redux/actions/currentAction";
import {separateContactsByGroups, sortArrayForAlphabet} from "../../utils/functions";
import ContactListGroup from "../ContactListGroup/ContactListGroup";
import { useAppContext } from "../../hooks/useAppContext";
import _ from "lodash";

interface ContactConsoleProps {
  systemContacts: any;
  recentContacts: any;
  acceptedList: any;
  teamMatesList: any;
  incomingContacts: any;
  outgoingContacts: any;
  nameOfCompany: any;
  currentUser: any;
}

const getStorageValue = (key: string, defaultValue: string | number) => {
  const valueSavedInLocalStorage = localStorage.getItem(key);
  if (typeof valueSavedInLocalStorage === "string") {
    return JSON.parse(valueSavedInLocalStorage);
  }
  return defaultValue;
};

const ContactConsole = (props: ContactConsoleProps) => {
  const {
    systemContacts,
    acceptedList,
    teamMatesList,
    nameOfCompany,
    currentUser
  } = props;
  const dispatch = useDispatch();
  const { L } = useAppContext();
  const systemName = L("contacts.header.system");
  //const recentName = L("contacts.header.recent");

  const [tabIndex, setTabIndex] = useState<number>(0);

  const allContacts = useMemo(() => {
    return separateContactsByGroups(acceptedList)
    // return {
      // [recentName]: { contacts: recentContacts },
    // }
  }, [/*recentContacts, */acceptedList])

  //console.log(allContacts)
  const companyContacts = useMemo(() => {
    return [currentUser, ...(teamMatesList ?? [])]?.sort(sortArrayForAlphabet)
  }, [teamMatesList, currentUser])

  // TODO For requestlist
  // const requestsContacts = useMemo(() => {
  //   return {
  //     incoming: incomingContacts,
  //     outgoing: outgoingContacts
  //   }
  // }, [incomingContacts, outgoingContacts])

    // <ContactRequestsMenu
  //   amountIncomingContacts={requestsContacts.incoming?.length}
  //   incomingRequestsContacts={requestsContacts.incoming}
  //   amountOutgoingContacts={requestsContacts.outgoing?.length}
  //   outgoingRequestsContacts={requestsContacts.outgoing}
  // />
  const tabNames = [
    { name: `${L("contacts.tab.all")} (${systemContacts?.length + acceptedList?.length})` },
    { name: `@${nameOfCompany} (${teamMatesList?.length + 1})` },
    // { name: `${L("contacts.title.requests")} (${incomingContacts?.length + outgoingContacts?.length})` }
  ];

  useEffect(() => { localStorage.setItem("currentTab", JSON.stringify(tabIndex.toString())) }, [tabIndex]);

  const handleContactsTabSwitch = useCallback((currentTabIndex: number) => {
    setTabIndex(currentTabIndex);
    dispatch(setCurrentContactsTab(currentTabIndex));
  },[])

  return (
    <div className="contacts-console-div">
      <Tab
        data={tabNames}
        onChange={handleContactsTabSwitch}
        size="sm"
        className="tabs-list"
        index={tabIndex}
      />
      {tabIndex !== 2 ? (
        <Overflow className="contacts-console-div-inside">
          <Dividers grey={false}/>
          {tabIndex === 0 ? (
              <>
                {systemName && systemContacts?.length &&
                (
                  <ContactListGroup
                    groupName={systemName}
                    groupContacts={systemContacts}
                  />
                )
              }
              {Object.keys(allContacts)?.map((contactGroup: string) => {
                if (allContacts[contactGroup].contacts.length) {
                  return (
                    <ContactListGroup
                      key={contactGroup}
                      groupName={contactGroup}
                      groupContacts={allContacts[contactGroup].contacts}
                    />
                  );
                }
                return null;
              })}
              </>
          ) : companyContacts.length !== 0 && (
            <ContactListGroup
              key={"teammates"}
              groupName={nameOfCompany}
              isCompanyList
              groupContacts={companyContacts}
            />
          )}
        </Overflow>
      ) : (
        <></>
      )}
    </div>
  );
};

export default React.memo(ContactConsole, _.isEqual);
