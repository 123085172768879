import "@clout-team/web-components/build/index.css";
import { PageLayout } from "@clout-team/web-components";
import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";

import ContactList from "../../components/ContactList/ContactList";
import Modal from "../../components/Modal/Modal";
import WorkingAreaContainer from "../../components/WorkingArea/WorkingAreaContainer";
import { RootState } from "../../redux/store";
import dayjs from "dayjs";

import packageJson from "../../../package.json";
import {VersionDialog} from "../../components/system/version";

localStorage.setItem('releaseDate', process.env.REACT_APP_RELEASE_DATE ?
  dayjs(process.env.REACT_APP_RELEASE_DATE).format("DD.MM.YYYY") + "" : "null");

const MainPage = () => {
  const [version, showVersion] = useState(false);

  useEffect(() => {
    window.addEventListener("storage", () => {
      showVersion(!!localStorage.getItem('show_version'));
    });

    const lastVersion = localStorage.getItem("contactVersion");
    const currentVersion = packageJson.version;

    if (lastVersion === null)
      localStorage.setItem("contactVersion", currentVersion);
    else if (lastVersion !== currentVersion) {
      localStorage.setItem("contactVersion", currentVersion);
    }
  }, []);

  const isModal = useSelector((state: RootState) => state.currentModal);

  return (
    // @ts-ignore
      <PageLayout>
        <PageLayout.Left id={"contacts-page-layout"}>
          <ContactList/>
        </PageLayout.Left>
        <PageLayout.Center className="working_area_contacts">
          <WorkingAreaContainer/>
        </PageLayout.Center>
        {isModal && <Modal nameOfModalToOpen={isModal}/>}
        { version && <VersionDialog /> }
      </PageLayout>
  );
};

export default MainPage;
