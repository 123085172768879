import {store} from "../../../redux/store";
import * as TYPES from "../../../redux/actionTypes/actionTypes";

export const listenerProfile: Record<string, (data: any) => void> = {
    'updateUser': (data: any) => {
        const newUserData = {
            avatar: data.avatar,
            domain: data.domain,
            hash: data.hash,
            name: data.name,
            surname: data.surname,
            group: data.name.split('')[0],
            login: data.login,
            is_friend: data.is_friend,
            is_company: data.is_friend,
            subject_id: data.subject_id,
        }

        store.dispatch({
            type: TYPES.UPDATE_MY_CONTACTS_LIST,
            payload: {
                hash: data.hash,
                contact: newUserData
            },
        })
    },

    'setProfile': (data: any) => {
        const newContact = {
            avatar: data.avatar,
            name: data.name,
            surname: data.surname,
            login: data.login
        }

        store.dispatch({
            type: TYPES.UPDATE_PROFILE,
            payload: {
                hash: data.hash,
                contact: newContact
            }
        })
    }
}