import { Button, Dialog, Input } from "@clout-team/web-components";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  makeRequestToUserAction,
  setCurrentModal,
  setFoundContact,
} from "../../redux/actions/currentAction";
import { RootState } from "../../redux/store";
import { useAppContext } from "../../hooks/useAppContext";

interface AddContactMessageModalProps {
  idOfCurrentContact: string;
}

const AddContactMessageModal = (props: AddContactMessageModalProps) => {
  const { idOfCurrentContact } = props;
  const dispatch = useDispatch();
  const currentStep = 0;
  const {L} = useAppContext();
  const { name, surname } = useSelector(
    (state: RootState) => state.currentUser
  );
  const [inputMessage, setInputMessage] = useState(
    `${L("contacts.input.welcome_message")} ${name} ${surname}`
  );
  const handleInputMessageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setInputMessage(event.target.value);
  const handleCloseFromModal = () => {
    dispatch(setFoundContact({ contact: {}, searchPhrase: "" }));
    dispatch(setCurrentModal(null));
  };

  return (
    <Dialog
      closeOnClickOutside={false}
      dialogTitle={{
        title: L("contacts.title.add_contact"),
        back: true,
        backOnClick: currentStep
          ? () => dispatch(setCurrentModal("add_new_contact"))
          : () => dispatch(setCurrentModal(null)),
      }}
      canBeClosed={true}
      onClose={
        currentStep
          ? handleCloseFromModal
          : () => dispatch(setCurrentModal(null))
      }
      dialogButtons={{
        right: [
          <Button
            key="cancel_button"
            children="Cancel"
            inverted
            size="lg"
            onClick={
              currentStep
                ? handleCloseFromModal
                : () => dispatch(setCurrentModal(null))
            }
          />,
          <Button
            key="send_button"
            children={L("contacts.button.send")}
            size="lg"
            onClick={() => {
              dispatch(
                makeRequestToUserAction(idOfCurrentContact, inputMessage)
              );
              currentStep
                ? dispatch(setCurrentModal("add_new_contact"))
                : dispatch(setCurrentModal(null));
            }}
            disabled={!inputMessage}
          />,
        ],
      }}
    >
      <Input
        type="textarea"
        label={L("contacts.label.personal_request")}
        height="lg"
        value={inputMessage}
        onChange={handleInputMessageChange}
      />
    </Dialog>
  );
};

export default AddContactMessageModal;
