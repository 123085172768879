import {translit} from "@clout-team/helpers";

import {Contact} from "../interfaces/contact";

export const filterBasedOnSearchPhrase = (
    arrayToFilter: Array<Contact>,
    filterPhrase: string
) => {
    //eslint-disable-next-line
    const preparedString = filterPhrase.replace(/[&\[\]\/\\#,+()$~%'":*?<>{}|]/g, '').trim();
    const searchIncludeRegex = new RegExp(
        `${preparedString}|${translit(preparedString)}`,
        "gim"
    );
    const searchBeginningRegex = new RegExp(
        `^${preparedString}|^${translit(preparedString)}`,
        "gmi"
    );
    const filteredArray = arrayToFilter.filter((oneElement: Contact) => {
        const fullname = `${oneElement.name} ${oneElement.surname}`;
        const fullnameReversed = `${oneElement.surname} ${oneElement.name}`;
        const profileNickname = oneElement.login.split("@")[0];
        const profileCompany = oneElement.login.split("@")[1];
        return (
            oneElement.name.match(searchIncludeRegex) ||
            oneElement.surname.match(searchIncludeRegex) ||
            oneElement.login.match(searchIncludeRegex) ||
            profileNickname.match(searchBeginningRegex) ||
            fullname.match(searchBeginningRegex) ||
            fullnameReversed.match(searchBeginningRegex) ||
            profileCompany.match(searchBeginningRegex)
        );
    });
    return filteredArray;
};

export const goToNewTabInBrowser = (pageUrl: string) => {
    window.open(pageUrl, "_blank");
};

export const calcLastSeenAgoTime = (
    lastSeenSeconds: number
): { number: string; timeFrame: string } => {
    const todaySeconds = Math.round(Date.now() / 1000);
    const differenceInMinutes = Math.floor((todaySeconds - lastSeenSeconds) / 60);
    switch (true) {
        case differenceInMinutes >= 1440:
            const howManyDaysAgo = Math.floor(differenceInMinutes / 1440);
            return {
                number: howManyDaysAgo.toString(),
                timeFrame: howManyDaysAgo === 1 ? "day" : "days",
            };
        case differenceInMinutes >= 60:
            const howManyHoursAgo = Math.floor(differenceInMinutes / 60);
            return {
                number: howManyHoursAgo.toString(),
                timeFrame: howManyHoursAgo === 1 ? "hour" : "hours",
            };
        case differenceInMinutes > 0:
            return {
                number: differenceInMinutes.toString(),
                timeFrame: differenceInMinutes === 1 ? "minute" : "minutes",
            };
        case differenceInMinutes === 0:
            return {
                number: "1",
                timeFrame: "minute",
            }
        default:
            return {number: "", timeFrame: ""};
    }
};

export const isValidCloutId = (inputString: string): boolean => {
    //eslint-disable-next-line
    const regexCloutId = new RegExp(/^[\w]{1}[\w-\.]*@[\w-]+$/, 'i');
    //eslint-disable-next-line
    // const regexPhone = new RegExp(/^[+][0-9]{5,}$/, 'im');
    return regexCloutId.test(inputString);
};

export const isValidPhone = (inputString: string): boolean => {
    return new RegExp(`^(((8|\\+8)|(7|\\+7))[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$`, 'i').test(inputString);
};
export const objectToSmallCase = (obj: any) => {
    let newObj = {...obj}
    Object.keys(obj).forEach((key) => {
        let value = obj[key];
        newObj[key.split(/(?=[A-Z])/).join('_').toLocaleLowerCase()] = value;
        newObj.last_online = obj.last_online && `${obj.last_online}`.length > 10 
            ? Math.floor(obj.last_online / 1000)
            : obj?.last_online;
    })
    return newObj
}

const toLocaleLowerCase = (text: string): string => String(text).toLocaleLowerCase()
const toLocaleUpperCase = (text: string): string => String(text).toLocaleUpperCase()
export const sortArrayForAlphabet = (a: Contact, b: Contact): (1 | 0 | -1) => {
    if (toLocaleLowerCase(a.name) > toLocaleLowerCase(b.name)) return 1
    else if (toLocaleLowerCase(a.name) < toLocaleLowerCase(b.name)) return -1
    return 0
}

export const separateContactsByGroups = (
  listOfContacts: Array<Contact>
): any => {
    let resultArray: any = [];
    const copyArray = Array.from(listOfContacts);
    copyArray?.sort(sortArrayForAlphabet).forEach((oneContact: Contact | any) => {
        const groupName = toLocaleUpperCase(
            oneContact.group 
            ?? oneContact?.name[0] 
            ?? oneContact?.surname[0]
        )
        resultArray[groupName] = resultArray[toLocaleUpperCase(groupName)]
          ? {contacts: [...resultArray[groupName].contacts, oneContact]}
          : {contacts: [oneContact]};
    });
    return resultArray;
};

export const getUrlParamsByKey = (key: string) => {
    let result: string = ``;
    let tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === key) result = tmp[1];
        });
    return result;
};
