import * as TYPES from "../actionTypes/actionTypes";

function newTeammatesListReducer(state: any = [], action: any) {
  switch (action.type) {
    case TYPES.SET_NEW_TEAMMATES_LIST:
      return action.payload;
    default:
      return state;
  }
}

export default newTeammatesListReducer;
