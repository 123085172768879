import currentUserReducer from "./currentUserReducer";
import currentContactReducer from "./currentContactReducer";
import myContactsListReducer from "./myContactsListReducer";
import foundContactReducer from "./foundContactReducer";
import currentContactsTabReducer from "./currentContactsTabReducer";
import currentModalReducer from "./currentModalReducer";
import newTeammatesListReducer from "./newTeammatesListReducer";
import activeChatReducer from "./activeChatReducer";
import { combineReducers } from "@reduxjs/toolkit";

export const reducers = {
  currentUser: currentUserReducer,
  currentContact: currentContactReducer,
  myContactsList: myContactsListReducer,
  foundContact: foundContactReducer,
  currentContactsTab: currentContactsTabReducer,
  currentModal: currentModalReducer,
  newTeammatesList: newTeammatesListReducer,
  activeChat: activeChatReducer
};

const appReducer = combineReducers(reducers);
export default appReducer;
