import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import AddContactMessageModal from "../AddContactMessageModal/AddContactMessageModal";
import AddNewContactContainer from "../AddNewContact/AddNewContactContainer";
import BlockOrDeleteModal from "../BlockOrDeleteModal/BlockOrDeleteModal";
import WelcomeNewTeammates from "../WelcomeNewTeammates/WelcomeNewTeammates";

interface ModalProps {
  nameOfModalToOpen: string;
}

const Modal: FC<ModalProps> = ({ nameOfModalToOpen }) => {
  const currentContact = useSelector(
    (state: RootState) => state.currentContact
  );
  const foundContact = useSelector(
    (state: RootState) => state.foundContact.contact
  );

  return useMemo(() => {
    switch (nameOfModalToOpen) {
      case "add_new_contact":
        return <AddNewContactContainer/>;
      case "add_contact_message":
        return (
          <AddContactMessageModal
            idOfCurrentContact={foundContact?.hash || currentContact?.hash}
          />
        );
      case "welcome_new_teammates":
        return <WelcomeNewTeammates/>;
      case "block_user":
        return (
          <BlockOrDeleteModal
            type="block"
            currentContact={foundContact?.hash ? foundContact : currentContact}
          />
        );
      case "delete_user":
        return (
          <BlockOrDeleteModal
            type="delete"
            currentContact={foundContact?.hash ? foundContact : currentContact}
          />
        );
      default:
        return null;
    }
  }, [currentContact, foundContact, nameOfModalToOpen]);
};

export default Modal;
