import React, { FC } from "react";

import AddNewContact from "./AddNewContact";
import FoundProfile from "./FoundProfile";

const TABS = [
  {
    component: AddNewContact,
  },
  {
    component: FoundProfile,
  },
];

const AddNewContactContainer: FC = () => {
  const tab = TABS[0];
  return <tab.component />;
};

export default AddNewContactContainer;
