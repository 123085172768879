// import { Contact } from '../../interfaces/contact'; Арташес должен добавить .json по умолчанию
import {
  BLOCK_CONTACT_URL,
  CONTACT_URL,
  SEARCH_CONTACT,
  UNBLOCK_CONTACT_URL,
} from "../../utils/urls";
import { API } from "../makeRequests";

export const getSystemContacts = async (): Promise<any> => {
  try {
    const uri = `${CONTACT_URL}system-list`;
    const response = await API.get(uri);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAcceptedAllContacts = async (): Promise<any> => {
  try {
    const uri = `${CONTACT_URL}accepted-list`;
    const response = await API.get(uri);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getTeamMatesContacts = async (): Promise<any> => {
  try {
    const uri = `${CONTACT_URL}all-list`;
    const response = await API.get(uri);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getIncomingRequestsContacts = async (): Promise<any> => {
  try {
    const uri = `${CONTACT_URL}send-by-friend-request-list`;
    const response = await API.get(uri);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getOutgoingRequestsContacts = async (): Promise<any> => {
  try {
    const uri = `${CONTACT_URL}send-request-list`;
    const response = await API.get(uri);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const searchContactByCloutId = async (cloutId: string): Promise<any> => {
  try {
    const uri = `${SEARCH_CONTACT}${cloutId}`;
    const response = await API.get(uri);
    if (!response.ok) throw new Error("Not found");
    const data = await response.json();
    return data.response.item;
  } catch (error) {
    console.log(error);
  }
};

/*------------------------------------------------ИЗМЕНЕНИЯ ОТНОШЕНИЙ С ПОЛЬЗОВАТЕЛЕМ -----------------------------------*/

export const makeRequestToUser = async (
  idOfPotentialFriend: string,
  welcomingMessage: string
): Promise<any> => {
  const uri = `${CONTACT_URL}request/${idOfPotentialFriend}`;
  const response = await API.put(uri, { String: welcomingMessage });
  if (!response.ok) throw new Error("Not found");
  return response;
};

export const acceptRequestFromUser = async (
  idOfFutureFriend: string
): Promise<any> => {
  const uri = `${CONTACT_URL}accept/${idOfFutureFriend}`;
  const response = await API.put(uri);
  if (!response.ok) throw new Error("Not found");
  return response;
};

export const rejectRequestFromUser = async (idOfUser: string): Promise<any> => {
  const uri = `${CONTACT_URL}reject/${idOfUser}`;
  const response = await API.put(uri);
  if (!response.ok) throw new Error("Not found");
  return response;
};

export const revokeRequestToUser = async (idOfUser: string): Promise<any> => {
  const uri = `${CONTACT_URL}withdraw/${idOfUser}`;
  const response = await API.put(uri);
  if (!response.ok) throw new Error("Not found");
  return response;
};

export const blockUser = async (idOfUser: string): Promise<any> => {
  const uri = `${BLOCK_CONTACT_URL}${idOfUser}`;
  const response = await API.post(uri);
  if (!response.ok) throw new Error("Not found");
  return response;
};

export const unblockOrDeleteUser = async (
  idOfUserToUnbclockOrDelete: string
): Promise<any> => {
  const uri = `${UNBLOCK_CONTACT_URL}${idOfUserToUnbclockOrDelete}`;
  const response = await API.post(uri);
  if (!response.ok) throw new Error("Not found");
  return response;
};
