import * as TYPES from "../actionTypes/actionTypes";
import { User } from "../../interfaces/user";

const isExist = (arr: [], hash: 0) => {
  return arr.find((u: any) => (u?.hash) === (hash))
}
const isComp = (contact: any) => {
  return (!!contact.isCompany || !!contact.is_company || contact?.contact_type === "COMPANY")
}
const isFriend = (contact: any) => {
  return (!!contact.is_friend || !!contact.isFriend)
}
function myContactsListReducer(state: any = { isLoading: true }, action: any) {
  const contact = action?.payload?.contact
  switch (action.type) {
    case TYPES.SET_MY_CONTACTS_LIST:
      return { ...state, isLoading: false, ...action.payload };
    case TYPES.ADD_MY_CONTACTS_LIST:
      let addeedContactsList = [...(state.accepted ?? []), (action.payload.contact)];
      return { ...state, accepted: addeedContactsList, ...action.payload };
    case TYPES.ADD_USER:
      const newOutgoingContacts = [...state.outgoing, action.payload.contact]
      return { ...state, outgoing: newOutgoingContacts }
    case TYPES.UPDATE_MY_CONTACTS_LIST:
      let newContactsList = state.accepted.map((item: any) => {
        return (item.hash) !== (action.payload.hash) ? item : { ...item, ...action.payload.contact }
      })
      let newTeammatesList = state.teammates.map((item: any) => {
        return (item.hash) !== (action.payload.hash) ? item : { ...item, ...action.payload.contact }
      })
      let newSystemList = state.system.map((item: any) => {
        return (item.hash) !== (action.payload.hash) ? item : { ...item, ...action.payload.contact }
      })
      if (contact.isCompany === 1 && !isExist(newTeammatesList, action.payload.hash)) {
        newTeammatesList.push(contact)
      }
      if (contact.isFriend === 1 && !isExist(newContactsList, action.payload.hash)) {
        newContactsList.push(contact)
      }
      // console.log(action.payload, newContactsList, newSystemList, newTeammatesList)
      return { ...state, accepted: newContactsList, teammates: newTeammatesList, system: newSystemList };
    case TYPES.ADD_MY_CONTACTS_LIST2:
      let newTeammatesList2 = state.teammates.map((item: any) => {
        return (item.hash) !== (action.payload.hash) ? item : { ...item, ...action.payload.contact }
      })

      let newContactsList2 = state.accepted.map((item: any) =>
        (item.hash) !== (action.payload.hash) ? item : { ...item, ...action.payload.contact }
      )

      if (isComp(contact) && !isExist(newTeammatesList2, action.payload.hash)) {
        newTeammatesList2.push(contact)
      }
      if (isFriend(contact) && !isExist(newContactsList2, action.payload.hash)) {
        newContactsList2.push(contact)
      }

      return { ...state, accepted: newContactsList2, teammates: newTeammatesList2 };
    case TYPES.UPDATE_PROFILE:
      const newSystem = state.system.map((item: User) => {
        if (item.hash === action.payload.hash) return { ...item, ...action.payload.contact }
        return item
      })
      let newTeammates = state.teammates.map((item: User) => {
        if (item.hash === action.payload.hash) return { ...item, ...action.payload.contact }
        return item
      })
      return {
        ...state,
        system: newSystem,
        teammates: newTeammates
      }
    case TYPES.UPDATE_USER:
      const updateUser = (arr = []) => {
        if(isExist(newTeammates3, action.payload.hash)){
          arr.map((item: User) => {
            if (item.hash === action.payload.hash) return { ...item, ...action.payload.contact }
            return item
          })
        }
      } 
      let newTeammates3 = state.teammates;
      updateUser(newTeammates3)
      let newSystem3 = state.teammates;
      updateUser(newSystem3)
      let newAccepted3 = state.accepted;
      updateUser(newAccepted3)
      return {
        ...state,
        system: newSystem3,
        accepted: newAccepted3,
        teammates: newTeammates3
      }
    case TYPES.GET_TEAMMATES:
      return { ...state, teammates: [...action.payload.users] }
    case TYPES.DELETED_CONTACT:
      const newRecent = state.recent.filter((item: User) => item.hash !== action.payload.hash)
      const newAccepted = state.accepted.filter((item: User) => item.hash !== action.payload)
      return { ...state, recent: newRecent, accepted: newAccepted }
    case TYPES.USER_ADD_YOU:
      const newIncomingContacts = [...state.incoming, action.payload.contact]
      return { ...state, incoming: newIncomingContacts }
    case TYPES.DELETE_COMPANY_CONTACT:
      const newTeammatesList3 = state.teammates.filter((item: User) => item.hash !== action.payload.hash)
      return { ...state, teammates: newTeammatesList3 }
    case TYPES.BLOCKED_USER_COMPANY:
      const newTeammatesList4 = state.teammates.map((item: User) => {
        if (item.hash === action.payload.hash) return { ...item, is_blocked: true }
        return item
      })
      return { ...state, teammates: newTeammatesList4 }
    default:
      return state;
  }
}

export default myContactsListReducer;
