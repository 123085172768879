import { Button } from "@clout-team/web-components";
import React from "react";
import { useDispatch } from "react-redux";

import { setCurrentModal } from "../../redux/actions/currentAction";
import ContactNotChosen from "./ContactNotChosen";
import { useAppContext } from "../../hooks/useAppContext";
import {isElectron} from "@clout-team/helpers/dist/utils/web-utils";

interface ContactNotChosenContainerProps {}

const ContactNotChosenContainer = (props: ContactNotChosenContainerProps) => {
  const dispatch = useDispatch();
  const {L} = useAppContext();
  const buttonsForContactNotChosen = [
    <Button
      key={0}
      children={L("profile.button.invite_teammates")}
      size="lg"
      onClick={() => {
        isElectron() ?
            // @ts-ignore
            window.ipcRenderer.send('request-set-dialog-key', 'dialog-add-members')
            :
            dispatch(setCurrentModal("add_new_contact"))
      }
      }
    />,
    /*<Button
      key={0}
      children={L("contacts.button.add_new_contact")}
      size="lg"
      color="white"
      onClick={() => dispatch(setCurrentModal("add_new_contact"))}
    />,*/
  ];
  return (
    <ContactNotChosen buttonsForContactNotChosen={buttonsForContactNotChosen} />
  );
};

export default ContactNotChosenContainer;
