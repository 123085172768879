import _ from "lodash";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import ContactConsole from "./ContactConsole";

const ContactConsoleContainer: FC = () => {
  const { system, recent, accepted, teammates, incoming, outgoing, domain, myCompany } =
    useSelector((store: RootState) => store.myContactsList);
  const isLoading = useSelector(
    (state: RootState) => state.myContactsList.isLoading
  );
  const currentUser =  useSelector((store: RootState) => store.currentUser)

  return isLoading ? (
    <div className={`loading`}> </div>
  ) : (
    <ContactConsole
      currentUser={currentUser}
      systemContacts={system}
      recentContacts={recent}
      acceptedList={accepted}
      teamMatesList={teammates}
      incomingContacts={incoming}
      outgoingContacts={outgoing}
      nameOfCompany={myCompany ?? domain}
    />
  );
};

export default React.memo(ContactConsoleContainer, _.isEqual);
