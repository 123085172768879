import { createContext } from "react";

export interface IAppContext {
  path?: string;
  active?: boolean;
}

export const defaultContext: IAppContext = {
  path: "/",
};

export const AppContext = createContext<IAppContext>(defaultContext);
