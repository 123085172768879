import {store} from "../../../redux/store";
import * as TYPES from "../../../redux/actionTypes/actionTypes";

export const listenerBiz: Record<string, (data: any) => void> = {
    'biz-edit-user': (data: any) => {
        const newUserData = {
            avatar: data.avatar,
            domain: data.domain,
            hash: data.hash,
            id: data.id,
            name: data.name,
            surname: data.surname,
            login: data.login,
            is_friend: data.is_friend,
            is_company: data.is_friend,
            subject_id: data.subject_id,
        }

        store.dispatch({
            type: TYPES.UPDATE_MY_CONTACTS_LIST,
            payload: {
                hash: data.hash,
                contact: newUserData
            },
        })
    },
    'biz-user-delete': (data: any) => {
        store.dispatch({
            type: TYPES.DELETE_COMPANY_CONTACT,
            payload: {
                hash: data.hash
            }
        })
/*
        // @ts-ignore
        window.ipcRenderer.send("delete-company-contact", { data })*/
    },
    'blockedUser': (data: any) => {
        store.dispatch({
            type: TYPES.BLOCKED_USER_COMPANY,
            payload: {
                hash: data.hash
            }
        })
/*        // @ts-ignore
        window.ipcRenderer.send('change-contact', {data})*/
    }
}