import PersonAddFilled from "@clout-team/icons/react/PersonAddFilled";
import { EmptyState } from "@clout-team/web-components";
import _ from "lodash";
import React from "react";
import { useAppContext } from "../../hooks/useAppContext";

interface ContactNotChosenProps {
  buttonsForContactNotChosen: any[];
}

const ContactNotChosen = (props: ContactNotChosenProps) => {
  const { buttonsForContactNotChosen } = props;
  const {L} = useAppContext();
  return (
    <EmptyState
      title={L("contacts.info.select_contact")}
      // text={L("contacts.title.select_alternative")}
      avatar={{
        icon: (
          <PersonAddFilled
            width={120}
            height={120}
          />
        ),
      }}
      // buttons={buttonsForContactNotChosen}
    />
  );
};

export default React.memo(ContactNotChosen, _.isEqual);
