import React, {useMemo} from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import { calcLastSeenAgoTime } from "../../utils/functions";
import ProfileCard from "./ProfileCard";
import { useAppContext } from "../../hooks/useAppContext";
import {Contact} from "../../interfaces/contact";

interface ProfileCardContainerProps {}

const ProfileCardContainer = (props: ProfileCardContainerProps) => {
  const idChosen = useSelector((state: RootState) => state.currentContact);
  const allContacts = useSelector((state: RootState) => {
    return [
      ...state.myContactsList.teammates,
      ...state.myContactsList.recent,
      ...state.myContactsList.system,
      ...state.myContactsList.accepted
    ]
  })

  const currentContact: Contact = useMemo(() => {

    return allContacts.find((item: Contact) => (item?.hash) === (idChosen))
  }, [allContacts, idChosen])

  const {L} = useAppContext();
  const {
    name,
    avatar,
    surname,
    lastEnter,
    login,
    phone,
    status,
    subjectId,
    domain
  } = currentContact;

  const { number, timeFrame } = calcLastSeenAgoTime(parseInt(lastEnter));
  const lastSeenAgo = `${number} ${timeFrame} ${L("contacts.info.last_seen_ago")}`;
  return (
    <ProfileCard
      contactId={"id"}
      currentContact={currentContact}
      isModal={false}
      canBeClosed={false}
      profileTitle={`${name} ${surname}`}
      lastSeenAgo={lastSeenAgo}
      profileCloutId={`${login}@${domain}`}
      domain={domain}
      profilePhoneNumber={`+${phone}`}
      profileStatus={status}
      profileAvatar={avatar}
      idOfChatWithContact={subjectId}
    />
  );
};

export default ProfileCardContainer;
