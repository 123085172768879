import * as TYPES from "../actionTypes/actionTypes";

function currentContactsTabReducer(state: string = "0", action: any) {
  switch (action.type) {
    case TYPES.SET_CURRENT_CONTACTS_TAB:
      return action.payload;
    default:
      return state;
  }
}

export default currentContactsTabReducer;
