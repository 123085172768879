import * as TYPES from "../actionTypes/actionTypes";

function currentContactReducer(state: string | null = null, action: any) {
  switch (action.type) {
    case TYPES.SET_CURRENT_CONTACT:
      return action.payload;
    default:
      return state;
  }
}

export default currentContactReducer;
