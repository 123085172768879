import _ from "lodash";
import React, { FC } from "react";

import ContactNotChosenContainer from "../ContactNotChosen/ContactNotChosenContainer";
import ProfileCardContainer from "../ProfileCard/ProfileCardContainer";

interface WorkingAreaProps {
  contactIsChosen: boolean;
  currentModal: string | null;
  idOfCurrentContact: string;
}

const WorkingArea: FC<WorkingAreaProps> = ({ contactIsChosen }) =>
  contactIsChosen ? <ProfileCardContainer /> : <ContactNotChosenContainer />;

export default React.memo(WorkingArea, _.isEqual);
