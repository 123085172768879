import { User } from "../../interfaces/user";
import * as TYPES from "../actionTypes/actionTypes";

function currentUserReducer(state: User | null = null, action: any) {
  switch (action.type) {
    case TYPES.SET_CURRENT_USER:
      return action.payload;
    default:
      return state;
  }
}

export default currentUserReducer;
