import React, {useEffect, useState} from "react";
import {getUrlParamByKey} from "@clout-team/helpers/dist/utils/web-utils";
import {objectToSmallCase} from "../../utils/functions";
import {ChatInfo} from "@clout-team/web-components";
import {ContactsController as CC} from "@clout-team/web-components";
const {Contacts} = CC;
import './NewWindowContact.scss'
import {useSelector} from "react-redux";
const NewWindowContact = () => {
  const [contact, setContact] = useState<any>(null)
  const store = useSelector((store: any) => store.currentUser)
  const queryContact = getUrlParamByKey('contact_id')
  useEffect(() => {
      Contacts.initial()
    },[])
    const _contactUser = window.contactsManager && Contacts.get(String(queryContact))

  useEffect(() => {
    setContact(_contactUser)
  },[_contactUser])
  return <div className={'new-window-popup'}>
    {
      contact ? <ChatInfo
        trafficLight={{
          visibly: true,
          hasOpenNewWindow: false,
          hasSubtract: true,
          hasMaximize: true,
          hasDismiss: true,
          hasDivider: false,
          onDismiss: () => (() => {}),
        }}
        onClose={(() => {})}
        contactViewMode
        hideShareBtn
        chatInfoActions={{
          openChat: () => contact?.subject_id && window.ipcRenderer.send('request-open-chat-or-message', contact?.subject_id, undefined),
          onCall: () => contact?.subject_id && window.callToChat(contact?.subject_id),
          onShareContact: (hash: any) => {},
          setUser: (user: any) => {
            setContact(user)
          }
        }}
        hideMoreBtn
        user={objectToSmallCase(store)}
        contact={objectToSmallCase(contact)}
        myProfile={Number(store?.hash) === Number(contact?.hash)}
        // @ts-ignore
        dialogProps={{
          isModal: false
        }}
      /> : <></>
    }
  </div>
}

export default  NewWindowContact