import {GET_TEAMMATES} from "../../utils/urls";
import {API} from "../makeRequests";

export const getContactCompany = async (companyId: number) => {
  try {
    const uri = `${GET_TEAMMATES}${companyId}`
    const response = await API.get(uri);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};