import './wdyr';

import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";
import {createRoot} from 'react-dom/client'
import { Provider as ReduxProvider } from "react-redux";

import App from "./App";
import { AppContext, defaultContext, IAppContext } from "./AppContext";
import { store } from "./redux/store";
import {Provider} from "@clout-team/web-components";

/*Sentry.init({
  dsn: "https://54e54c363ca84bc5b68a6878498b79bd@o1248645.ingest.sentry.io/6408727",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});*/

interface IProps extends IAppContext {
  name: string;
  container: HTMLElement;
}

const Render = (context: IAppContext = defaultContext) => {
  return (
      <ReduxProvider store={store}>
          <AppContext.Provider value={context}>
            <Provider>
              <App />
            </Provider>
          </AppContext.Provider>
      </ReduxProvider>
  );
};
const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<Render />)