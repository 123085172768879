import { Contact } from "../../interfaces/contact";
import * as TYPES from "../actionTypes/actionTypes";

interface FoundContact {
  contact: Contact | null;
  searchPhrase: string;
}

function foundContactReducer(
  state: FoundContact = { contact: null, searchPhrase: "" },
  action: any
) {
  switch (action.type) {
    case TYPES.SET_FOUND_CONTACT:
      return action.payload;
    default:
      return state;
  }
}

export default foundContactReducer;
