import _ from "lodash";
import React, { FC } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import WorkingArea from "./WorkingArea";

const WorkingAreaContainer: FC = () => {
  const {currentContact, currentModal} = useSelector((state: RootState) => state);

  return (
    <WorkingArea
      contactIsChosen={currentContact !== null}
      currentModal={currentModal}
      idOfCurrentContact={""}
    />
  );
};

export default React.memo(WorkingAreaContainer, _.isEqual);
