import "./BlockOrDeleteModal.scss";

import { Button, Dialog, Text } from "@clout-team/web-components";
import React from "react";
import { useDispatch } from "react-redux";

import { Contact } from "../../interfaces/contact";
import {
  blockUserAction,
  setCurrentContact,
  setCurrentModal,
  setFoundContact,
  unblockOrDeleteUserAction
} from "../../redux/actions/currentAction";
import { useAppContext } from "../../hooks/useAppContext";

interface BlockOrDeleteModalProps {
  type: string;
  currentContact: Contact;
}

const BlockOrDeleteModal = (props: BlockOrDeleteModalProps) => {
  const { type, currentContact } = props;
  const dispatch = useDispatch()
  
  const currentStep = 0;
  const { L } = useAppContext();
  const { hash, name, surname } = currentContact;
  const contactName = `${name} ${surname}`;
  let mainActionButton = "";
  let titleBlockOrDelete = "";
  let dialogContent = "";
  let handleMainButtonClick;
  let wrapMainActionFunction = (mainActionFunction: any) => {
    mainActionFunction();
    dispatch(setCurrentContact(null));
    dispatch(setCurrentModal(null));
  };
  const handleCloseFromModal = () => {
    dispatch(setFoundContact({ contact: {}, searchPhrase: "" }));
    dispatch(setCurrentModal(null));
  };
  switch (type) {
    case "block":
      mainActionButton = L("contacts.button.block");
      handleMainButtonClick = () => wrapMainActionFunction(() => dispatch(blockUserAction(hash)));
      // @ts-ignore
      titleBlockOrDelete = L("contacts.title.block_this_user", { variables: { user: contactName } });
      dialogContent = L("contacts.info.block_warning");
      break;
    case "delete":
      mainActionButton = L("contacts.button.delete");
      handleMainButtonClick = () => wrapMainActionFunction(() => dispatch(unblockOrDeleteUserAction(hash)));
      // @ts-ignore
      titleBlockOrDelete = L("contacts.title.delete_this_user", { variables: { user: contactName } });
      break;
    default:
      break;
  }
  return (
    <Dialog
      closeOnClickOutside={false}
      dialogTitle={{ title: titleBlockOrDelete }}
      canBeClosed={true}
      onClose={handleCloseFromModal}
      dialogButtons={{
        right: [
          <Button
            key="cancel_button"
            children="Cancel"
            inverted
            size="lg"
            onClick={
              currentStep
                ? () => dispatch(setCurrentModal("add_new_contact"))
                : () => dispatch(setCurrentModal(null))
            }
          />,
          <Button
            key={`${props.type}_button`}
            children={mainActionButton}
            size="lg"
            onClick={handleMainButtonClick}
          />
        ]
      }}
    >
      <Text children={dialogContent} className="text-blocked-modal"/>
    </Dialog>
  );
};

export default BlockOrDeleteModal;
