import IconPerson from "@clout-team/icons/react/Person";
import IconPersonFilled from "@clout-team/icons/react/PersonAdd";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppContext } from "../AppContext";
import { setCurrentModal } from "../redux/actions/currentAction";
import { RootState } from "../redux/store";
import { goToNewTabInBrowser } from "../utils/functions";
import { useAppContext } from "./useAppContext";

export const useInit = () => {
  const dispatch = useDispatch();
  const {L} = useAppContext();
  const { path, active = true } = useContext(AppContext);

  const numberOfNewTeammates = useSelector(
    (state: RootState) => state.newTeammatesList
    );
  const numberOfIncomingRequests = useMemo(() => numberOfNewTeammates.incoming, [numberOfNewTeammates.incoming])

  const onClick = useCallback(() => {
    if (numberOfNewTeammates.length) {
      dispatch(setCurrentModal("welcome_new_teammates"));
    } else {
      goToNewTabInBrowser(path ?? "http://clout-web-contacts.clout.one");
    }
  }, [dispatch, numberOfNewTeammates.length, path]);

  const menuItem = useMemo(
    () => ({
      id: "contacts",
      active,
      counter: numberOfIncomingRequests?.length + numberOfNewTeammates?.length,
      description: L("contacts.left_bar.contacts"),
      icon: (
        <IconPerson
          aria-hidden="true"
          className="menu-item__icon menu-item__icon--regular"
        />
      ),
      iconFilled: (
        <IconPersonFilled
          aria-hidden="true"
          className="menu-item__icon menu-item__icon--active"
        />
      ),
      onClick
    }),
    [
      active,
      L,
      numberOfIncomingRequests?.length,
      numberOfNewTeammates?.length,
      onClick
    ]
  );


  return {};
};
