import { PROFILE_GET } from "../../utils/urls";
import { API } from "../makeRequests";
import {Profile} from "../../interfaces/profile";

export const getProfile = async (): Promise<Profile | undefined> => {
  try {
    const uri = PROFILE_GET;
    const response = await API.get(uri);
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.log(error);
  }
};
