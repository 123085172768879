import "./WelcomeNewTeammates.scss";

import { Button, ContextualMenu, Dialog } from "@clout-team/web-components";
import React from "react";
import { useDispatch } from "react-redux";

import { setCurrentModal } from "../../redux/actions/currentAction";
import { contextChildrenForWelcome } from "../../utils/mock";
import ContactListItemContainer from "../ContactListItem/ContactListItemContainer";
import { useAppContext } from "../../hooks/useAppContext";

interface WelcomeNewTeammatesProps {
}

const WelcomeNewTeammates = (props: WelcomeNewTeammatesProps) => {
  const dispatch = useDispatch();
  const { L } = useAppContext();
  const getContextChildren = contextChildrenForWelcome.map(
    (oneNewProfile: any) => (
      <ContactListItemContainer
        key={oneNewProfile.hash}
        contact={oneNewProfile}
        iconRightForOutgoingRequests={
          <Button
            children="Go to chat"
            size="sm"
            color="white"
            onClick={() => {}}
          />
        }
      />
    )
  );

  return (
    <div>
      <Dialog
        closeOnClickOutside={false}
        dialogTitle={{
          title: L("contacts.title.welcome_teammates", {
            count: contextChildrenForWelcome.length,
            variables: { number: contextChildrenForWelcome.length }
          })
        }}
        dialogButtons={{
          right: [
            <Button children={L("contacts.button.continue")} size="lg"/>
          ]
        }}
        onClose={() => dispatch(setCurrentModal(null))}
      >
        <ContextualMenu
          isModal={false}
          withWrapper
          className="contextual-menu"
          children={getContextChildren}
        />
      </Dialog>
    </div>
  );
};

export default WelcomeNewTeammates;
