import { Button, ContactsController as CC, getChatTitle, getUserStatus, ListItem, Text } from "@clout-team/web-components";
import React, {useMemo, useState} from "react";

import { Contact } from "../../interfaces/contact";
import { User } from "../../interfaces/user";
import { useAppContext } from "../../hooks/useAppContext";

import "./ContactListItem.scss"
import { addFriend } from "../../api/user";

import {DialogAssembly} from "@clout-team/web-components";
import { ChatInfoFunctions } from "@clout-team/web-components";
import { store } from "../../redux/store";
import { ADD_MY_CONTACTS_LIST2 } from "../../redux/actionTypes/actionTypes";
interface ContactListItemProps {
  contact: Contact;
  myself: User;
  isContactActive: boolean;
  handleClickOnContact: (contact: Contact) => void;
  iconRightForIncomingRequests?: any;
  iconRightForOutgoingRequests?: any;
  isCompanyList?: boolean
}

const {Contacts} = CC;

const ContactListItem = React.memo((props: ContactListItemProps) => {
  const {L} = useAppContext();
  const {
    contact,
    myself,
    isContactActive,
    handleClickOnContact,
    isCompanyList
  } = props;
  const {dayjsLocale} = useAppContext()
  const [showAddContact, setShowPopup] = useState(false)
  const addContact = useMemo(() => {
    return showAddContact && <DialogAssembly.SendFirstMessageModal 
        avatar={contact?.avatar} 
        contactName={`${contact?.surname} ${contact?.name}`} 
        myName={`${myself?.name} ${myself?.surname}`}
        onClose={() => setShowPopup(false)}
        onSubmit={(message: string) => {
          try {
            addFriend(contact?.hash, message)
            setShowPopup(false)
            if(window.contactsManager){
              Contacts.updateProfile({
                ...contact,
                is_friend: true
              })
            }
            store.dispatch({
              type: ADD_MY_CONTACTS_LIST2,
              payload: {
                hash: contact.hash,
                contact: {
                  ...contact,
                  is_friend: true,
                  isFriend: 1,
                }
              },
            })
          } catch (e) {
            console.log(e)
          }
        }}
      />
  },[contact, showAddContact])
  const rightButtons = useMemo(() => {
    if(contact?.is_you || !['DEACTIVATED', "DELETED", "BLOCKED"].includes(String(contact.status))){
      return undefined
      // @ts-expect-error
    } else if(!contact?.is_friend && !contact?.isFriend){
      return <Button onClick={() => setShowPopup(true)}>{L("contacts.button.add_contact")}</Button> 
    } else {
      return <Text type='disabled'>{L("contacts.button.added") ?? "Added"}</Text>
    }
  },[contact])
  
  return (
    <>
      {showAddContact ? addContact : null}
      <ListItem
        size="md"
        title={ getChatTitle({...contact, is_you: myself?.hash === contact.hash}, L)}
        description={`${contact.login}@${contact.domain}`}
        avatar={{ 
          src: contact.avatar || "", 
          srcset: contact.avatar, 
          type: "private", 
          status:  contact?.domain !== 'bot' ? getUserStatus(contact) : undefined,
          lastSeen: ChatInfoFunctions.calcLastSeenAgoTime(contact?.last_online, dayjsLocale) 
        }}
        active={isContactActive}
        onClick={() => handleClickOnContact(contact)}
        // iconRight={iconRightForIncomingRequests || iconRightForOutgoingRequests}
        iconRight={isCompanyList ? rightButtons : undefined}
        className="list_item-custom"
      />
    </>
  );
});

export default ContactListItem;
